<template>
  <div class="auth-wrapper">
    
    <b-row class="auth-inner">
      <b-col sm="12">
        <form class="auth-login-form" @submit.prevent="login">
          <img class="auth-login-form-logo" src="@/assets/images/logo.svg" />
          <b-form-group>
            <label for="login-email">
              {{ $t("forms.email") }} <span class="required">*</span>
            </label>
            <b-form-input
              id="login-email"
              v-model="userEmail"
              name="login-email"
            />
          </b-form-group>

          <b-form-group>
            <label for="login-password">
              {{ $t("forms.password") }} <span class="required">*</span>
            </label>
            <b-form-input
              id="login-password"
              v-model="password"
              type="password"
              class="form-control-merge"
              name="login-password"
            />
          </b-form-group>

          <div class="login-actions">
            <b-button type="submit" variant="secondary" block>
              {{ $t("forms.login") }}
            </b-button>
            <router-link class="forgot-password" to="/forgot-password"
              >{{ $t("forms.forgotPassword") }}</router-link
            >
          </div>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import Toast from '@/components/Toast.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {},
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      password: '',
      userEmail: '',
    }
  },
  methods: {
    login() {
      this.$JWT.login({
        email: this.userEmail,
        password: this.password,
      }).then(response => {
        if (response.code !== 401) {
          this.$JWT.setToken(response.data.token)
          this.$JWT.setRefreshToken(response.data.token)
          this.$JWT.axiosIns
            .get(`${this.apiUrl}${response.data.user}`)
            .then(userResponse => {
              const userData = userResponse.data
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  const toast = useToast()
                  this.$helper.showToast(toast, Toast, this.$t("messages.welcome"), '', 'success')
                })
            })
        }
      })
      
    },
  },
}
</script>
